import React, { useMemo, FC, useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'
import qs from 'query-string'
import posthog from 'posthog-js'

import { Icon } from 'ui/icon'

import {
  SearchAutocomplete,
  SearchAutocompleteResults,
  SearchAutocompleteSeeMore,
} from 'components/search-autocomplete'
import { InitDropdown } from 'components/search-autocomplete/init-dropdown'
import { Modal } from 'components/responsive-modal'

import useRouteMatch from 'lib/hooks/useRouteMatch'
import useTranslation from 'lib/hooks/useTranslation'
import useLayoutEffect from 'lib/hooks/useLayoutEffect'

import { buildPath } from 'lib/utils'

import { COUNTRY_ROUTE, COUNTRY_ROUTE_V1, SEARCH_ROUTE } from 'lib/constants/routes'
import { DATA_LAYER_EVENT } from 'lib/constants'

import { AUTO_SUGGEST_SEACH_QUERY } from 'gql/queries/search'

import s from './styles.module.scss'

export interface SearchModalProps {
  destinationId?: string
  countryId?: string
  open: boolean
  closeModal: () => void
  variant?: 'modal' | 'drawer'
  subVariant?: 'gplp' | null
  initStateCompType?: 'popular-activities' | 'destination' | 'country'
  onSubmit?: (arg0: any, arg1?: any) => void
  onChange: (arg0: any) => void
  query: string
  loading: boolean
  data?: any
  allowEmptyValueSubmit?: boolean
  trackEvent?: TrackEventType
  preventSubmit?: boolean
  resetQueryOnClose?: boolean
  placeHolder?: string
  dontTrackOnSubmit?: boolean
  initialStateComponent?: React.ReactNode
}

const SearchModal: FC<SearchModalProps> = ({
  destinationId,
  countryId,
  closeModal,
  open,
  variant = 'modal',
  subVariant,
  /* initStateCompType, */ onSubmit,
  onChange,
  query: searchValue,
  data,
  preventSubmit = false,
  resetQueryOnClose = true,
  placeHolder,
  loading,
  allowEmptyValueSubmit,
  trackEvent,
  dontTrackOnSubmit,
  initialStateComponent,
}) => {
  const [, setFakeState] = useState<boolean | undefined>(undefined)
  const autocompleteRef = useRef<{ focus: () => void }>(null)

  const router = useRouter()
  const { t } = useTranslation('common')

  const isSearchRoute = Boolean(useRouteMatch(SEARCH_ROUTE))
  const isOldCountryRoute = Boolean(useRouteMatch(COUNTRY_ROUTE))
  const isNewCountryRoute = Boolean(useRouteMatch(COUNTRY_ROUTE_V1))
  const isCountryRoute = isOldCountryRoute || isNewCountryRoute

  // force re rendering to make sure auto focus working as intended on ios devices
  useLayoutEffect(() => setFakeState(open), [open])

  useEffect(() => {
    if (open) autocompleteRef.current?.focus()
  }, [open])

  const resultOptions = useMemo(
    () => ({
      destinations: data?.[AUTO_SUGGEST_SEACH_QUERY.queryName.destinations]?.destinations,
      products: data?.[AUTO_SUGGEST_SEACH_QUERY.queryName.products]?.products,
    }),
    [data]
  )
  const hideSeeMore =
    !Object.values(resultOptions).reduce((total, item = []) => item.length + total) && loading

  const handleSubmit = (query: string, e?: any) => {
    const { destinationId: destId, countryId: countryIdParam } = qs.parse(location.search)

    const payload = {
      [DATA_LAYER_EVENT.ECOMMERCE.SEARCH_TERM]: query,
    }
    window?.dataLayer?.push({
      event: DATA_LAYER_EVENT.SEARCH,
      ...payload,
    })
    posthog.capture(DATA_LAYER_EVENT.SEARCH, payload)

    if (onSubmit) {
      onSubmit?.(query, e)
    } else if (isCountryRoute) {
      router.push(buildPath(SEARCH_ROUTE, {}, { keyword: query, countryIds: countryIdParam || countryId }))
    } else if (isSearchRoute) {
      router.push(
        buildPath(
          SEARCH_ROUTE,
          {},
          {
            query,
            destinationIds: destId,
            countryIds: countryIdParam || countryId,
          }
        )
      )
    } else {
      router.push(buildPath(SEARCH_ROUTE, {}, { keyword: query, destinationIds: destinationId }))
    }
    closeModal?.()
  }

  const handleOptionClick = (item: any, type?: string) => {
    onChange('')
    closeModal?.()
    if (type === 'query') {
      const payload = {
        [DATA_LAYER_EVENT.ECOMMERCE.SEARCH_TERM]: item,
      }
      window?.dataLayer?.push({
        event: DATA_LAYER_EVENT.SEARCH,
        ...payload,
      })
      posthog.capture(DATA_LAYER_EVENT.SEARCH, payload)
    }
  }

  return (
    <Modal open={open} fullScreen hideCloseButton animation="fade">
      <div className={s.body}>
        <div className={cn(s.header, s[variant])}>
          <SearchAutocomplete
            ref={autocompleteRef}
            placeholder={placeHolder || t('action.searchForDestinationsOrActivities')}
            value={searchValue || ''}
            unstyledDropdown
            alwaysShowDropdown
            variant="white-rounded"
            subVariant={subVariant}
            hideButton
            hasSearchIcon
            trackEvent={trackEvent}
            onSubmit={preventSubmit ? closeModal : handleSubmit}
            dontTrackOnSubmit={dontTrackOnSubmit}
            onChange={onChange}
            allowEmptyValueSubmit={allowEmptyValueSubmit}
            initialStateComponent={
              initialStateComponent || (
                <InitDropdown>
                  <InitDropdown.RecentSearches onSelect={handleOptionClick} />
                  <InitDropdown.TrendingSearches
                    destinationId={destinationId}
                    countryId={countryId}
                    onSelect={handleOptionClick}
                  />
                </InitDropdown>
              )
            }
          >
            <SearchAutocompleteResults
              loading={loading}
              options={resultOptions}
              onOptionClick={handleOptionClick}
              className="mt-2"
            />
            <SearchAutocompleteSeeMore
              value={searchValue || ''}
              onClick={() => handleSubmit(searchValue)}
              hidden={hideSeeMore}
              trackEvent={trackEvent}
              flatButton={!resultOptions.destinations?.length && !resultOptions.products?.length}
            />
          </SearchAutocomplete>
          <button
            onClick={() => {
              resetQueryOnClose && onChange('')
              closeModal()
            }}
          >
            <Icon name={variant === 'drawer' ? 'caret-left' : 'delete-x'} />
          </button>
        </div>
      </div>
    </Modal>
  )
}

export { SearchModal }
